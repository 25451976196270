import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';

import { Heading, NamedLink, IconEmailSent, InlineTextButton, IconClose } from '../../components';

import css from './AuthenticationPage.module.css';
import classNames from 'classnames';

const EmailVerificationInfo = props => {
  const {
    name,
    email,
    onResendVerificationEmail,
    resendErrorMessage,
    sendVerificationEmailInProgress,
    isIndividual = false,
  } = props;

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
      <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
    </InlineTextButton>
  );

  const fixEmailLink = (
    <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
      <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
    </NamedLink>
  );

  return (
    <div className={classNames(css.formContent, css.formLoginContent)}>
      <div className={css.rightSide}>
        <div className={css.content}>
          <NamedLink
            className={css.verifyClose}
            name={isIndividual ? 'LandingPatientPage' : 'NewListingPage'}
          >
            <span className={css.closeText}>
              <FormattedMessage id="AuthenticationPage.verifyEmailClose" />
            </span>
            <IconClose rootClassName={css.closeIcon} />
          </NamedLink>
          <IconEmailSent className={css.modalIcon} />
          <Heading as="h1" rootClassName={css.modalTitle}>
            <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
          </Heading>
          <p className={css.modalMessage}>
            <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
          </p>
          {resendErrorMessage}

          <div className={css.bottomWrapper}>
            <p className={css.modalHelperText}>
              {sendVerificationEmailInProgress ? (
                <FormattedMessage id="AuthenticationPage.sendingEmail" />
              ) : (
                <FormattedMessage
                  id="AuthenticationPage.resendEmail"
                  values={{ resendEmailLink }}
                />
              )}
            </p>
            <p className={css.modalHelperText}>
              <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationInfo;
